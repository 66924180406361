<template>
  <div class="col-12 datos-content p-0">
    <div
      v-if="dataCart.direcciones.length > 0 && !editData"
      class="form-datos pt-0"
    >
      <div
        v-for="(dir1, index) in dataCart.direcciones"
        :key="index + isEdit"
        class="col-12 pb-3"
      >
        <div
          class="container-card-address position-relative d-flex flex-wrap align-items-center"
          style="background-color: #F7F3F0;"
          :class="
            dir1.direccion_id == dirSelect
              ? 'container-card-address-selected'
              : ''
          "
          @click="changeDir(dir1)"
        >
          <span
            class="action_card-edit"
            @click="
              dir1.direccion_editar == 0
                ? $bvModal.show('modal-login')
                : dataEdit(dir1)
            "
            >Editar</span
          >
          <input
            v-model="dirSelect"
            type="radio"
            class="my-auto"
            :value="dir1.direccion_id"
          />
          <p class="ps-4">
            {{ dir1.direccion }} <br />
            {{ dir1.barrio }} <br />
            {{
              dir1.ciudad_texto
                .split(',')[0]
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }},
            {{
              dir1.ciudad_texto
                .split(',')[1]
                .trim()
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
            <br />
            {{ dir1.observaciones }}
          </p>
          <br class="d-block d-md-none" />
          <p class="bold ms-auto mt-3 mt-md-0">
            {{ dir1.valor_envio }}
          </p>
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <div
          v-if="tokenUser"
          class="col-12 col-md-6 text-center mt-4 pe-0 pe-md-2"
        >
          <button
            class="w-100 secondary-button px-5"
            @click="
              dataCart.carrito_editar == 0
                ? $bvModal.show('modal-login')
                : (editData = true)
            "
          >
            Agregar Dirección
          </button>
        </div>
        <div class="col-12 col-md-6 text-center my-4 ps-0 ps-md-2 ms-auto">
          <button
            class="btn-next-step-payment btn-primary"
            @click="sendFormAddress()"
          >
            Ir a pagar
          </button>
        </div>
      </div>
    </div>
    <div v-else class="form-datos">
      <span v-if="tokenUser" class="go-back-summary" @click="restartDefault()"
        >Volver</span
      >
      <div class="payment-input-left">
        <select
          v-model="selectedDep"
          :style="campoDepRequerido ? 'border-color: red' : ''"
          class="payment-select w-100"
          @blur="loseFocus('Dep')"
        >
          <option :value="null">Departamento</option>
          <option
            v-for="(dep, index) in deps"
            :key="index"
            :value="dep.codigo"
            >{{
              dep.descripcion.toLowerCase().replace(/^\w/, d => d.toUpperCase())
            }}</option
          >
        </select>
        <span v-if="campoDepRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="selectedDep" class="newlatter-error-input"
          >Departamento</span
        >
      </div>
      <div class="payment-input-right">
        <select
          v-model="selectedCity"
          :style="campoCityRequerido ? 'border-color: red' : ''"
          class="payment-select w-100"
          @blur="loseFocus('Ciu')"
        >
          <option :value="null">Ciudad</option>
          <option
            v-for="(city, index) in citys"
            :key="index"
            :value="city.codigo"
            >{{
              city.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}</option
          >
        </select>
        <span v-if="campoCityRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="selectedCity" class="newlatter-error-input">Ciudad</span>
      </div>
      <div class="payment-input-left">
        <input
          v-model="dir"
          :style="dir && campoDireccionRequerido ? 'border-color: red' : ''"
          class="payment-input w-100"
          autocomplete="address-line1"
          type="street-address"
          maxlength="50"
          placeholder="Dirección"
          @blur="loseFocus('Dir')"
        />
        <span v-if="campoDireccionRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="dir" class="newlatter-error-input">Dirección</span>
      </div>
      <div class="payment-input-right">
        <input
          v-model="barrio"
          autocomplete="address-line2"
          class="payment-input w-100"
          type="text"
          maxlength="50"
          placeholder="Barrio"
        />
        <span v-if="barrio" class="newlatter-error-input">Barrio</span>
      </div>
      <div class="w-100 position-relative">
        <input
          v-model="observacionesDir"
          class="payment-input w-100"
          type="text"
          maxlength="50"
          :placeholder="
            observacionesDir
              ? 'Observaciones'
              : 'Observaciones (Ej: Apartamento, torre, piso, etc)'
          "
        />
        <span v-if="observacionesDir" class="newlatter-error-input"
          >Observaciones</span
        >
      </div>
      <div v-if="dataEntregadir" class="mt-4 w-100">
        <p class="bold">Tiempo de entrega apróximado:</p>
        <div
          class="container-step-envio-payment flex-wrap justify-content-between"
        >
          <div class="w-100 d-flex justify-content-between">
            <p class="mb-0 pe-4">
              Hasta {{ dataEntregadir.dias_entrega }} días hábiles, lo recibes
              entre el {{ dataEntregadir.fechasEnvio.fechaInicio }} y el
              {{ dataEntregadir.fechasEnvio.fechaFinal }}.
            </p>
            <p class="mb-0 bold">
              ${{ new Intl.NumberFormat('es').format(dataEntregadir.total) }}
            </p>
          </div>
          <div v-if="dataCart.infoEstrategiaVentas" class="w-100 mt-2">
            <div
              class="p-2 rounded"
              style="background-color: #f7f7f7; display: inline-block;"
            >
              <a
                class="link-envio-gratis"
                target="_blank"
                :href="dataCart.infoEstrategiaVentas.link_pagina_condiciones"
              >
                Aplica <b>envío gratis</b>. Ver términos y condiciones.
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap mt-4">
        <div
          class="col-12 col-md-6 pe-0 pe-md-2 mt-4 container-btn-add-new-dir-payment"
        >
          <button class="btn-primary px-4 w-100" @click="sendAddress()">
            {{ isEdit ? 'Actualizar dirección' : 'Ir a pagar' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: Number,
    },
    entregarOtraDir: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      higher: false,
      createAcound: false,
      dataTemp: {},
      isEdit: false,
      dirSelect: 0,
      // nombreDestinatario: '',
      dir: '',
      barrio: '',
      selectedDep: null,
      selectedCity: null,
      observacionesDir: '',
      idDir: null,
      editData: false,
      tokenUser: '',
      citys: [],
      dataEntregadir: null,
      // Variables para validación de campos //
      // campoNombreDestinatarioRequerido: false,
      campoDireccionRequerido: false,
      campoBarrioRequerido: false,
      campoDepRequerido: false,
      campoCityRequerido: false,
      ////////////////////////////////////////
    }
  },
  computed: {
    ...mapState('cart', [
      'dataCart',
      'updateValidations',
      'validateAddres',
      'productsCart',
    ]),
    ...mapState('products', ['deps']),
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla_id,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  watch: {
    dirSelect() {
      let dataDir = this.$store.state.cart.dataCart.direcciones.find(
        e => e.direccion_id == this.dirSelect,
      )
      this.$store.state.cart.dataCart.idDir = this.dirSelect
      // this.$store.state.cart.dataCart.destinatario = dataDir.destinatario
      this.$store.state.cart.dataCart.cliente_direccion = dataDir.direccion
      this.$store.state.cart.dataCart.cliente_barrio = dataDir.barrio
      this.$store.state.cart.dataCart.cliente_departamento =
        dataDir.departamento_id
      this.$store.state.cart.dataCart.cliente_ciudad = dataDir.ciudad_id
      this.$store.state.cart.dataCart.observaciones = dataDir.observaciones
    },
    // nombreDestinatario(){
    //     //if(!window.localStorage.getItem('accessToken')){
    //         this.$store.state.cart.dataCart.destinatario = this.nombreDestinatario;
    //     //}
    // },
    dir() {
      //if(!window.localStorage.getItem('accessToken')){
      this.$store.state.cart.dataCart.cliente_direccion = this.dir
      //}
    },
    barrio() {
      //if(!window.localStorage.getItem('accessToken')){
      this.$store.state.cart.dataCart.cliente_barrio = this.barrio
      //}
    },
    async selectedDep() {
      if (this.selectedDep) {
        //if(!window.localStorage.getItem('accessToken')){
        this.$store.state.cart.dataCart.cliente_departamento = this.selectedDep
        //}
        this.selectedCity = null
        this.citys = (await this.deps.find(e => e.codigo == this.selectedDep))
          ? await this.deps.find(e => e.codigo == this.selectedDep)?.ciudades
          : null
      }
    },
    async selectedCity() {
      //if(!window.localStorage.getItem('accessToken')){
      this.$store.state.cart.dataCart.cliente_ciudad = this.selectedCity
      if (this.selectedCity && this.selectedDep) {
        this.$store.state.loaderDerek = true
        var data = new FormData()
        data.append('departamento_codigo', this.selectedDep)
        data.append('ciudad_codigo', this.selectedCity)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/shippings/calculate_shipping',
          data: data,
        })
          .then(response => {
            this.dataEntregadir = response.data.envio
          })
          .catch(e => {
            console.log('Error getEnvio', e)
          })
          .finally(() => {
            this.$store.state.loaderDerek = false
          })
      } else {
        this.dataEntregadir = null
      }
      //}
    },
    observacionesDir() {
      //if(!window.localStorage.getItem('accessToken')){
      this.$store.state.cart.dataCart.observaciones = this.observacionesDir
      //}
    },
    updateValidations() {
      this.campoDireccionRequerido = !(
        this.dataCart.cliente_direccion.length > 4
      )
      // this.campoBarrioRequerido = !(this.dataCart.cliente_barrio.length > 4);
      this.campoDepRequerido = !this.dataCart.cliente_departamento
      this.campoCityRequerido = !this.dataCart.ciudad_id
      if (!this.validateAddres) {
        this.editData = true
      }
    },
    async dataCart() {
      await this.updateDataDir()
    },
    editData() {
      if (!this.isEdit) {
        // this.nombreDestinatario = '';
        this.dir = ''
        this.barrio = ''
        this.selectedDep = null
        this.selectedCity = null
        this.observacionesDir = ''
        this.idDir = null
      }
    },
  },
  async mounted() {
    if (this.entregarOtraDir) {
      this.editData = true
    }
    await this.updateDataDir()
  },
  methods: {
    ...mapActions('products', ['getDataCitys']),
    ...mapActions('cart', ['updateInfoCart']),
    ...mapActions('user', ['updateDirection', 'addNewDirection']),
    async updateDataDir() {
      this.dirSelect = this.dataCart.direcciones[0]?.direccion_id
      this.dataCart.idDir = this.dataCart.direcciones[0]?.direccion_id
      this.tokenUser = window.localStorage.getItem('accessToken')
      if (!(this.deps.length > 0)) {
        await this.getDataCitys()
      }
      if (!this.dataCart.direccion_id) {
        // this.nombreDestinatario = this.dataCart.destinatario;
        this.dir = this.dataCart.cliente_direccion
        this.barrio = this.dataCart.cliente_barrio
        this.selectedDep = this.dataCart.cliente_departamento
          ? this.dataCart.cliente_departamento
          : null
        this.citys = (await this.deps.find(e => e.codigo == this.selectedDep))
          ? await this.deps.find(e => e.codigo == this.selectedDep)?.ciudades
          : null
        this.selectedCity =
          this.dataCart.cliente_ciudad != ''
            ? this.dataCart?.cliente_ciudad
            : null
        this.observacionesDir = this.dataCart.observaciones
      }
    },
    addNewDir() {
      this.restartDefault()
      this.isEdit = false
    },
    async updateDirectionCheckOut() {
      if (
        /* this.nombreDestinatario?.length > 4 && */ this.dir?.length > 4 &&
        this.barrio?.length > 4 &&
        this.selectedCity
      ) {
        await this.updateDirection({
          /* destinatario: this.nombreDestinatario, */ direccion: this.dir,
          barrio: this.barrio,
          departamento_id: this.selectedDep,
          ciudad_id: this.selectedCity,
          observaciones: this.observacionesDir,
          direccion_id: this.idDir,
        }).then(() => {
          this.editData = false
          this.sendFormAddress()
        })
      } else {
        // this.campoNombreDestinatarioRequerido = !(this.nombreDestinatario?.length > 4 );
        this.campoDireccionRequerido = !(this.dir?.length > 4)
        // this.campoBarrioRequerido = !(this.barrio?.length > 4);
        this.campoDepRequerido = !this.selectedDep
        this.campoCityRequerido = !this.selectedCity
      }
    },
    async addnewDir() {
      if (
        /*this.nombreDestinatario?.length > 4 && */ this.dir?.length > 4 &&
        this.barrio?.length > 4 &&
        this.selectedCity
      ) {
        await this.addNewDirection({
          /*destinatario: this.nombreDestinatario, */ direccion: this.dir,
          barrio: this.barrio,
          departamento_id: this.selectedDep,
          ciudad_id: this.selectedCity,
          observaciones: this.observacionesDir,
        }).then(() => {
          this.editData = false
        })
      } else {
        // this.campoNombreDestinatarioRequerido = !(this.nombreDestinatario.length > 4 );
        this.campoDireccionRequerido = !(this.dir.length > 4)
        this.campoBarrioRequerido = !(this.barrio.length > 4)
        this.campoDepRequerido = !this.selectedDep
        this.campoCityRequerido = !this.selectedCity
      }
    },
    async dataEdit(dataEditDir) {
      this.editData = true
      this.isEdit = true
      // this.nombreDestinatario = dataEditDir.destinatario;
      this.dir = dataEditDir.direccion
      this.barrio = dataEditDir.barrio
      this.selectedDep = dataEditDir.departamento_id
      this.citys = (await this.deps.find(e => e.codigo == this.selectedDep))
        ? await this.deps.find(e => e.codigo == this.selectedDep)?.ciudades
        : null
      this.selectedCity = dataEditDir.ciudad_id ? dataEditDir?.ciudad_id : null
      this.observacionesDir = dataEditDir.observaciones
      this.idDir = dataEditDir.direccion_id
    },
    restartDefault() {
      // this.nombreDestinatario = '';
      this.dir = ''
      this.barrio = ''
      this.selectedDep = null
      this.selectedCity = null
      this.observacionesDir = ''
      this.idDir = null
      this.isEdit = false
      this.editData = false
      // this.campoNombreDestinatarioRequerido = false;
      this.campoDireccionRequerido = false
      // this.campoBarrioRequerido = false;
      this.campoDepRequerido = false
      this.campoCityRequerido = false
    },
    changeDir(dir) {
      this.dirSelect = dir.direccion_id
      this.dir = dir.direccion
      this.barrio = dir.barrio
      this.selectedDep = dir.departamento_id
      this.selectedCity = dir.ciudad_id ? dir.ciudad_id : null
      this.observaciones = dir.observaciones
    },
    loseFocus(input) {
      if (input == 'Dir') {
        this.campoDireccionRequerido = !(this.dir?.length > 4)
      }
      // if(input == 'Bar'){
      //     this.campoBarrioRequerido = !(this.barrio?.length > 4);
      // }
      if (input == 'Dep') {
        this.campoDepRequerido = !this.selectedDep
      }
      if (input == 'Ciu') {
        this.campoCityRequerido = !this.selectedCity
      }
      // if(input == 'Dest'){
      //     this.campoNombreDestinatarioRequerido = !(this.nombreDestinatario?.length > 4);
      // }
    },
    async sendFormAddress() {
      if (this.entregarOtraDir || (!this.isEdit && this.editData)) {
        this.$store.state.cart.dataCart.idDir = ''
      }
      this.$store.state.loaderDerek = true
      await this.updateInfoCart({ step: '3' })
      this.$store.state.loaderDerek = false
    },
    async sendAddress() {
      if (
        this.dir?.length > 4 /*&& this.barrio?.length > 4*/ &&
        this.selectedDep &&
        this.selectedCity /*&& this.nombreDestinatario?.length > 4*/
      ) {
        this.sendFormAddress()
        // DK-CheckoutShipping
        // window.dataLayer.push({
        //   event: 'DK-CheckoutShipping',
        //   step: 3,
        //   carrito_id: this.dataCart.carrito_id,
        //   ecommerce: {
        //     currencyCode: 'COP',
        //     products: this.getDataAnalytics,
        //     subtotal: this.dataCart.carrito_subtotal,
        //     total: this.dataCart.carrito_total,
        //   },
        //   profile: {
        //     email: this.dataCart.cliente_correo,
        //     tipo_doc: this.dataCart.tipodoc,
        //     documento: this.dataCart.cliente_documento,
        //     nombre: this.dataCart.cliente_nombre,
        //     apellidos: this.dataCart.cliente_apellidos,
        //     celular: this.dataCart.cliente_celular,
        //   },
        //   shipping: {
        //     direccion: this.dataCart.cliente_direccion,
        //     barrio: this.dataCart.cliente_barrio,
        //     departamento_id: this.dataCart.cliente_departamento,
        //     ciudad_id: this.dataCart.cliente_ciudad,
        //     observaciones: this.dataCart.observaciones,
        //   },
        //   value: this.dataCart.carrito_total,
        // })
        // this.$gtag.event('add_shipping_info', {
        //   coupon: this.dataCart.cupon,
        //   currency: 'COP',
        //   items: this.cart,
        //   shipping_tier: this.selectedCity,
        //   value: this.dataEntregadir.total,
        // })
      } else {
        this.loseFocus('Dir')
        this.loseFocus('Bar')
        this.loseFocus('Dep')
        this.loseFocus('Ciu')
        this.loseFocus('Dest')
      }
    },
  },
}
</script>
