<template>
  <div>
    <div class="form-datos pt-0">
      <div class="payment-input-left">
        <input
          v-model="dataCart.cliente_nombre"
          autocomplete="given-name"
          class="payment-input w-100"
          type="given-name"
          placeholder="Nombre"
          @blur="loseFocus('Nom')"
          @focus="campoNombresRequerido = false"
        />
        <span v-if="campoNombresRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="dataCart.cliente_nombre" class="newlatter-error-input"
          >Nombre</span
        >
      </div>
      <div class="payment-input-right">
        <input
          v-model="dataCart.cliente_apellidos"
          autocomplete="family-name"
          class="payment-input w-100"
          type="family-name"
          placeholder="Apellidos"
          @blur="loseFocus('Apell')"
          @focus="campoApellidosRequerido = false"
        />
        <span v-if="campoApellidosRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="dataCart.cliente_apellidos" class="newlatter-error-input"
          >Apellidos</span
        >
      </div>
      <div class="payment-input-left">
        <select
          v-model="dataCart.tipodoc"
          class="payment-select w-100"
          @blur="loseFocus('TDoc')"
          @focus="campoTipoDocumentoRequerido = false"
          @change="dataCart.cliente_documento = ''"
        >
          <option value="">Tipo de documento</option>
          <option value="CC">Cédula de ciudadanía</option>
          <option value="CE">Cédula de extranjería</option>
          <option value="PAS">Pasaporte</option>
          <option value="NIT">Nit</option>
        </select>
        <span v-if="campoTipoDocumentoRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="dataCart.tipodoc" class="newlatter-error-input"
          >Tipo de documento</span
        >
      </div>
      <div class="payment-input-right">
        <input
          v-model="dataCart.cliente_documento"
          class="payment-input w-100"
          :style="
            dataCart.cliente_documento &&
            !validateDNI &&
            campoDocumentoRequerido
              ? 'border-color: red'
              : ''
          "
          :type="
            dataCart.tipodoc && dataCart.tipodoc == 'PAS' ? 'text' : 'number'
          "
          placeholder="Documento"
          @blur="loseFocus('Doc')"
          @focus="campoDocumentoRequerido = false"
        />
        <span v-if="campoDocumentoRequerido" class="payment-error-label">{{
          dataCart.cliente_documento
            ? 'El campo ' + dataCart.tipodoc + ' es invalido'
            : 'Este campo es obligatorio'
        }}</span>
        <span v-if="dataCart.cliente_documento" class="newlatter-error-input"
          >Documento</span
        >
      </div>
      <div class="payment-input-left">
        <input
          v-model="dataCart.cliente_celular"
          :style="
            dataCart.cliente_celular && campoTelefonoRequerido
              ? 'border-color: red'
              : ''
          "
          autocomplete="tel"
          class="payment-input w-100"
          type="tel"
          name="celular"
          placeholder="Celular de contacto"
          @blur="loseFocus('Tel')"
          @focus="campoTelefonoRequerido = false"
        />
        <span v-if="campoTelefonoRequerido" class="payment-error-label">{{
          dataCart.cliente_celular
            ? 'Parece que el número de teléfono no es válido'
            : 'Este campo es obligatorio'
        }}</span>
        <span v-if="dataCart.cliente_celular" class="newlatter-error-input"
          >Celular de contacto</span
        >
      </div>
    </div>
    <button class="btn-next-step-payment btn-primary" @click="sendData()">
      Siguiente
    </button>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  components: {},
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      createAcound: false,
      shortView: true,
      isAdult: false,
      token: '',
      // Variables para validación de campos //
      campoTelefonoRequerido: false,
      campoNombresRequerido: false,
      campoApellidosRequerido: false,
      campoTipoDocumentoRequerido: false,
      campoDocumentoRequerido: false,
      campomayor18Requerido: false,
      ////////////////////////////////////////
    }
  },
  computed: {
    /* eslint-disable */
    ...mapState('cart', [
      'dataCart',
      'updateValidations',
      'validateDataUser',
      'faltanCampos',
      'productsCart',
    ]),
    validateEmail: function() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.dataCart.cliente_correo)
    },
    validateDNI: function() {
      if (this.dataCart.tipodoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.dataCart.cliente_documento) &&
          this.dataCart.cliente_documento.length >= 7 &&
          this.dataCart.cliente_documento.length <= 10
        )
      } else if (this.dataCart.tipodoc == 'CE') {
        const re2 = /([0-9]){6}/
        return (
          re2.test(this.dataCart.cliente_documento) &&
          this.dataCart.cliente_documento.length == 6
        )
      } else if (this.dataCart.tipodoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){5}/
        return (
          re3.test(this.dataCart.cliente_documento) &&
          this.dataCart.cliente_documento.length >= 5
        )
      } else if (this.dataCart.tipodoc == 'NIT') {
        return this.dataCart.cliente_documento.length == 9
      }
      return false
    },
    validatePhone: function() {
      const re = /^3[0-9]{9}/
      return (
        re.test(this.dataCart.cliente_celular) &&
        this.dataCart.cliente_celular.length < 11
      )
    },
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla_id,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  watch: {
    isAdult() {
      this.$store.state.cart.dataCart.isAdult = this.isAdult
    },
    dataCart() {
      if (
        this.dataCart.carrito_editar == 1 &&
        this.dataCart.cliente_direccion == ''
      ) {
        this.$store.state.cart.dataCart.isAdult = false
      } else {
        this.$store.state.cart.dataCart.isAdult = true
        if (this.dataCart.carrito_editar == 0) {
          this.shortView = true
        }
      }
      this.token = window.localStorage.getItem('accessToken')
    },
  },
  methods: {
    ...mapActions('cart', ['updateInfoCart']),
    loseFocus(input) {
      if (input == 'Tel') {
        this.campoTelefonoRequerido = !this.validatePhone
      }
      if (input == 'Nom') {
        const re = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/;
        this.campoNombresRequerido = !(this.dataCart.cliente_nombre.length >= 4
        && re.test(this.dataCart.cliente_nombre))
      }
      if (input == 'Apell') {
        const re = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/;
        this.campoApellidosRequerido = !(
          this.dataCart.cliente_apellidos.length >= 4
          && re.test(this.dataCart.cliente_apellidos)
        )
      }
      if (input == 'Doc') {
        this.campoDocumentoRequerido = !this.validateDNI
      }
      if (input == 'TDoc') {
        this.campoTipoDocumentoRequerido = !this.dataCart.tipodoc
      }
    },
    async sendData() {
      if (
        this.dataCart.tipodoc &&
        this.validateDNI &&
        this.dataCart.cliente_apellidos.length >= 4 &&
        this.dataCart.cliente_nombre.length >= 4 &&
        this.validatePhone
      ) {
        this.$store.state.loaderDerek = true
        await this.updateInfoCart({ step: '2' }).then(() => {
          // DK-CheckoutProfile
          // window.dataLayer.push({
          //   event: 'DK-CheckoutProfile',
          //   step: 2,
          //   carrito_id: this.dataCart.carrito_id,
          //   ecommerce: {
          //     currencyCode: 'COP',
          //     products: this.getDataAnalytics,
          //     subtotal: this.dataCart.carrito_subtotal,
          //     total: this.dataCart.carrito_total,
          //   },
          //   profile: {
          //     email: this.dataCart.cliente_correo,
          //     tipo_doc: this.dataCart.tipodoc,
          //     documento: this.dataCart.cliente_documento,
          //     nombre: this.dataCart.cliente_nombre,
          //     apellidos: this.dataCart.cliente_apellidos,
          //     celular: this.dataCart.cliente_celular,
          //   },
          //   value: this.dataCart.carrito_total,
          // })
          if (this.dataCart.sin_iva == 0) {
            // this.$bvModal.show('modal-dia-sin-iva-payment')
          }
        })
        this.$store.state.loaderDerek = false
      } else {
        this.loseFocus('Tel')
        this.loseFocus('Nom')
        this.loseFocus('Apell')
        this.loseFocus('Doc')
        this.loseFocus('TDoc')
      }
    },
  },
  mounted() {
    this.$store.state.cart.dataCart.isAdult = false
    this.token = window.localStorage.getItem('accessToken')
    if (!this.token) {
      this.shortView = false
    }
    if (
      this.dataCart.carrito_editar == 1 &&
      this.dataCart.cliente_direccion == ''
    ) {
      this.$store.state.cart.dataCart.isAdult = false
    } else {
      this.$store.state.cart.dataCart.isAdult = true
      if (this.dataCart.carrito_editar == 0) {
        this.shortView = true
      }
    }
  },
}
</script>
