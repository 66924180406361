<template>
  <div>
    <div class="product-list">
      <div
        v-for="(product, index) in productGroup"
        :key="index"
        class="product-payment"
      >
        <img :src="product.referencia.imagenes[0]" alt="" />
        <div class="product-detail-payment">
          <p class="product-detail-title">
            {{
              product.producto_titulo
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </p>
          <span class="product-detail-size-talla me-2">
            Talla: {{ product.referencia.talla.name }} / Color:
            {{
              product.referencia.color_nombre
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
            <br />
            Cantidad: {{ product.count_products }}
          </span>
          <div
            v-if="product.dia_siniva == 1 && product.status != 0"
            class="d-flex flex-wrap flex-xl-nowrap justify-content-center justify-content-xl-end my-3"
          >
            <p class="product-detail-price" style="font-size: 1rem;">
              <del class="ps-0 pe-2"
                >${{
                  new Intl.NumberFormat('de-DE').format(product.precio)
                }}</del
              >
              $
              {{
                new Intl.NumberFormat('de-DE').format(
                  product.producto_precio_siniva,
                )
              }}
            </p>
            <div class="tags-product-detail ms-3">
              <div class="tag-dia-sin-iva">
                <p class="mb-0"><b>SIN IVA</b></p>
              </div>
            </div>
          </div>
          <div v-else class="w-100">
            <p
              v-if="product.status == 1"
              class="w-100 product-detail-price mt-0 d-flex"
              style="font-size: 1rem;"
            >
              <del v-if="product.precio_antes > 0" class="p-0 me-2"
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    product.precio_antes * product.count_products,
                  )
                }}</del
              >
              ${{
                new Intl.NumberFormat('de-DE').format(
                  product.precio * product.count_products,
                )
              }}
            </p>
            <p v-if="product.status == 0" class="pt-2 mb-0 fs-6 mt-auto">
              <span class="out-of-stock">Este producto está agotado</span>
            </p>
          </div>
          <div
            v-if="product.tags.length > 0"
            class="d-flex w-100 flex-wrap mb-2"
          >
            <span
              v-for="(tag, index1) in product.tags"
              :key="index1"
              class="d-flex pt-1 me-2"
            >
              <label
                :style="
                  `background-color: ${tag.color_fondo}; color: ${tag.color_texto}`
                "
                style="font-size: 14px; padding: .1rem .4rem; font-weight: bold;"
                >{{ tag.tag }}</label
              >
            </span>
          </div>
          <router-link
            v-if="product.status == 0"
            class="my-1"
            style="color: #0d6efd;"
            :to="{
              name: 'TiendaCategory',
              params: { category: product.categoria.toLowerCase() },
            }"
          >
            Ver sugerencias
          </router-link>
        </div>
      </div>
      <div class="divider-payment-products" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('cart', ['productsCart']),
    productGroup() {
      let products_group = []
      this.productsCart.filter(product => {
        if (
          !products_group.find(
            element =>
              element.producto_sku == product.producto_sku &&
              element.referencia.color_id == product.referencia.color_id &&
              element.referencia.talla.id == product.referencia.talla.id &&
              product.producto_aplica_estrategia == 0 &&
              element.producto_aplica_estrategia != 1 &&
              element.descuento_aplica_estrategia ==
                product.descuento_aplica_estrategia,
          ) ||
          product.producto_aplica_estrategia == 1
        ) {
          let dataTemp = product
          dataTemp.count_products = 1
          products_group.push(product)
        } else {
          products_group = products_group.filter(element => {
            if (
              element.producto_sku == product.producto_sku &&
              element.referencia.color_id == product.referencia.color_id &&
              element.referencia.talla.id == product.referencia.talla.id &&
              element.producto_aplica_estrategia == 0 &&
              element.descuento_aplica_estrategia ==
                product.descuento_aplica_estrategia
            ) {
              element.count_products += 1
            }
            return element
          })
        }
      })
      return products_group
    },
  },
}
</script>
