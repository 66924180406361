<template>
  <div>
    <div class="payment-cupo">
      <!-- PASO CONSULTAR CUENTA -->
      <div v-if="stepCupo == ''">
        <p
          style="max-width: 660px; margin-left: auto;
          margin-right: auto; margin-bottom: 0;"
        >
          <b>
            Accede al cupo personal Quac: Rápido, fácil y para todos.
          </b>
        </p>
        <p style="max-width: 660px; margin-left: auto; margin-right: auto;">
          ¿Sin historial crediticio? ¡No hay problema! En Quac no necesitas un
          pasado crediticio para empezar a escribir tu historia con nosotros.
        </p>
        <div
          style="max-width: 630px;"
          class="mx-auto alert alert-clover-default py-2"
        >
          <p class="p-0 m-0">
            Financiamos esta compra,
            <strong>
              págala en hasta 10 cuotas quincenales.
            </strong>
          </p>
        </div>
        <p>Ingresa el número de documento y haz clic en el botón consultar.</p>
        <div class="check-nit">
          <div
            class="col-12 col-md-auto payment-input p-0 mb-4 position-relative"
            style="border-bottom: none"
          >
            <input
              v-model="ccUser"
              :disabled="true"
              style="background-color: white; opacity: .6"
              class="payment-input w-100 payment-email-input py-0 my-0"
              placeholder="Cédula de ciudadanía"
              type="text"
            />
            <span
              v-if="ccUser"
              class="newlatter-error-input"
              style="opacity: .6"
              >Cédula de ciudadanía</span
            >
          </div>
          <button
            class="payment-button-input btn-primary"
            @click="validateCupoQuac()"
          >
            Consultar
          </button>
        </div>
        <div v-if="errorCupo" class="my-3 text-center">
          <span class="msgError">{{ msgerrorCupo }}</span>
        </div>
      </div>
      <!-- PASO REQUIERE SOLICITUD -->
      <div v-if="stepCupo == 'REQUIERE_SOLICITUD'">
        <p>
          <span class="bold">¡Aún no tienes cupo personal!</span> <br />
          Paga tu compra en 10 cómodas cuotas quincenales con nuestro cupo
          personal. Aplica diligenciando el formulario a continuación.
        </p>
        <!-- <div class="box-message">
                    <strong>REQUISITOS</strong><br>
                    Para solicitar tu Cupo personal, debes ser Colombiano mayor de edad,no estar reportado en centrales de riesgo y contar con correo electrónico y número de celular personal.
                </div> -->
        <div class="content-form">
          <div class="payment-input-left">
            <input
              v-model="soliCC"
              :disabled="true"
              class="payment-input w-100"
              style="background-color: white; opacity: .6"
              type="number"
              placeholder="Número de documento"
            />
            <span
              v-if="validateData && !validateSoliCC"
              class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span
              v-if="soliCC"
              class="newlatter-error-input"
              style="opacity: .6"
              >Número de documento</span
            >
          </div>
          <div class="payment-input-right">
            <input v-model="fechExp" class="payment-input w-100" type="date" />
            <span
              v-if="validateData && !validateFechExp"
              class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span class="newlatter-error-input">Fecha de expedición</span>
          </div>
          <div class="payment-input-left">
            <input
              v-model="soliName"
              class="payment-input w-100"
              style="background-color: white;"
              type="given-name"
              autocomplete="given-name"
              placeholder="Nombre"
            />
            <span
              v-if="validateData && !validateSoliName"
              class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="soliName" class="newlatter-error-input">Nombre</span>
          </div>
          <div class="payment-input-right">
            <input
              v-model="soliLastname"
              class="payment-input w-100"
              style="background-color: white;"
              type="family-name"
              placeholder="Apellidos"
            />
            <span
              v-if="validateData && !validateSoliLastName"
              class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="soliLastname" class="newlatter-error-input"
              >Apellidos</span
            >
          </div>
          <div class="payment-input-left">
            <select
              v-model="selectDep"
              class="w-100 payment-select"
              style="background-color: white"
              aria-label="Selección de ciudad"
            >
              <option value="null">Departamento</option>
              <option v-for="(dep, index) in deps" :key="index" :value="dep">{{
                dep.descripcion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}</option>
            </select>
            <span
              v-if="validateData && !selectDep"
              style="top: 60px"
              class="error-inputs"
              >Este campo es obligatorio</span
            >
          </div>
          <div class="payment-input-right">
            <select
              v-model="selectCity"
              class="w-100 payment-select"
              style="background-color: white"
              aria-label="Selección de ciudad"
            >
              <option value="null">Ciudad</option>
              <option
                v-for="(city, index) in citys"
                :key="index"
                :value="city"
                >{{
                  city.descripcion
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}</option
              >
            </select>
            <span
              v-if="validateData && !selectCity"
              style="top: 60px"
              class="error-inputs"
              >Este campo es obligatorio</span
            >
          </div>
          <div class="payment-input-left">
            <input
              v-model="soliPhone"
              class="payment-input w-100"
              style="background-color: white;"
              type="tel"
              name="celular"
              placeholder="Celular"
            />
            <span
              v-if="validateData && !validateSoliPhone"
              class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="soliPhone" class="newlatter-error-input">Celular</span>
          </div>
          <div class="payment-input-right">
            <input
              id="email"
              v-model="soliEmail"
              class="payment-input w-100"
              style="background-color: white;"
              type="email"
              placeholder="Correo electrónico"
            />
            <span
              v-if="validateData && !validateEmailSoli"
              class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="soliEmail" class="newlatter-error-input"
              >Correo electrónico</span
            >
          </div>
          <div class="mx-auto">
            <p>
              Al hacer clic en "Continuar", acepta el
              <router-link
                class="fs-6"
                target="_blank"
                :to="{
                  name: 'Politicas',
                  params: { slug: 'politica-de-privacidad' },
                }"
                >tratamiento de datos y consulta en centrales de
                riesgo.</router-link
              >
            </p>
          </div>
          <div class="col-12 col-md-10 col-xl-6 mx-auto">
            <button
              class="btn-primary"
              style="width: 100%"
              @click="validateDNI()"
            >
              Continuar con el cupo personal
            </button>
          </div>
          <div class="my-3 mx-auto w-100 text-center">
            <span v-if="msgError" class="msgError">{{ messagerError }}</span>
          </div>
        </div>
      </div>
      <!-- PASO ENVIAR CODIGOS PAGARE -->
      <div
        v-if="
          stepCupo == 'ENVIAR_CODIGO_SMS_PAGARE' ||
            stepCupo == 'FIRMA__PAGARE_SMS' ||
            stepCupo == 'FIRMA__PAGARE_PREAPROBADO'
        "
      >
        <div class="box-message" style="background-color: transparent;">
          <!-- A continuación, lee y acepta los términos y condiciones consignados en los documentos: Pagaré, Carta de instrucciones pagaré, Tratamiento de datos personales, Centrales de Riesgo y Garantías. -->
          <b>{{ nameCliente }}</b
          >, para finalizar el pedido es necesario ingresar la firma electrónica
          donde aceptas los términos y condiciones consignados en los
          documentos: Pagaré, Carta de instrucciones pagaré, Tratamiento de
          datos personales, Centrales de Riesgo y Garantías.
        </div>
        <div class="my-3 d-flex flex-wrap">
          <div class="d-flex flex-wrap mb-3">
            <div class="col-12 col-lg-6 px-2 d-flex mb-3">
              <div class="check-box-condicion">
                <p class="mb-0">
                  Pagaré y la Carta de Instrucciones del Pagaré
                </p>
                <a
                  href="http://api.devstirpe.com.co/docs/pagare_carta_instrucciones.pdf"
                  target="_blank"
                  download
                >
                  <svg
                    id="Shopicons_Light_Invoice"
                    style="margin-right: .3rem"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.271"
                    height="18.271"
                    viewBox="0 0 18.271 18.271"
                  >
                    <path
                      id="Trazado_5060"
                      data-name="Trazado 5060"
                      d="M0,0H18.271V18.271H0Z"
                      fill="none"
                    />
                    <g id="Shopicon" transform="translate(2.284 1.523)">
                      <rect
                        id="Rectángulo_2511"
                        data-name="Rectángulo 2511"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 3.426)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2512"
                        data-name="Rectángulo 2512"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 6.471)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2513"
                        data-name="Rectángulo 2513"
                        width="3.806"
                        height="0.761"
                        transform="translate(3.426 9.516)"
                        fill="#000000"
                      />
                      <path
                        id="Trazado_5061"
                        data-name="Trazado 5061"
                        d="M6,19.225,9.426,17.7l3.426,1.523L16.277,17.7,19.7,19.225V4H6ZM6.761,4.761h12.18V18.054l-2.355-1.047-.309-.137-.309.137-3.117,1.385L9.735,17.007l-.309-.137-.309.137L6.761,18.054Z"
                        transform="translate(-6 -4)"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  Ver documento
                </a>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-flex px-2 mb-3">
              <div class="check-box-condicion">
                <p class="mb-0">Contrato de crédito</p>
                <a
                  href="http://api.devstirpe.com.co/docs/contrato_credito.pdf"
                  target="_blank"
                  download
                >
                  <svg
                    id="Shopicons_Light_Invoice"
                    style="margin-right: .3rem"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.271"
                    height="18.271"
                    viewBox="0 0 18.271 18.271"
                  >
                    <path
                      id="Trazado_5060"
                      data-name="Trazado 5060"
                      d="M0,0H18.271V18.271H0Z"
                      fill="none"
                    />
                    <g id="Shopicon" transform="translate(2.284 1.523)">
                      <rect
                        id="Rectángulo_2511"
                        data-name="Rectángulo 2511"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 3.426)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2512"
                        data-name="Rectángulo 2512"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 6.471)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2513"
                        data-name="Rectángulo 2513"
                        width="3.806"
                        height="0.761"
                        transform="translate(3.426 9.516)"
                        fill="#000000"
                      />
                      <path
                        id="Trazado_5061"
                        data-name="Trazado 5061"
                        d="M6,19.225,9.426,17.7l3.426,1.523L16.277,17.7,19.7,19.225V4H6ZM6.761,4.761h12.18V18.054l-2.355-1.047-.309-.137-.309.137-3.117,1.385L9.735,17.007l-.309-.137-.309.137L6.761,18.054Z"
                        transform="translate(-6 -4)"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  Ver documento
                </a>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-flex px-2 mb-3">
              <div class="check-box-condicion">
                <p class="mb-0">Leer Contrato de Fianza</p>
                <a
                  href="http://api.devstirpe.com.co/docs/contrato_fianza.pdf"
                  target="_blank"
                  download
                >
                  <svg
                    id="Shopicons_Light_Invoice"
                    style="margin-right: .3rem"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.271"
                    height="18.271"
                    viewBox="0 0 18.271 18.271"
                  >
                    <path
                      id="Trazado_5060"
                      data-name="Trazado 5060"
                      d="M0,0H18.271V18.271H0Z"
                      fill="none"
                    />
                    <g id="Shopicon" transform="translate(2.284 1.523)">
                      <rect
                        id="Rectángulo_2511"
                        data-name="Rectángulo 2511"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 3.426)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2512"
                        data-name="Rectángulo 2512"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 6.471)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2513"
                        data-name="Rectángulo 2513"
                        width="3.806"
                        height="0.761"
                        transform="translate(3.426 9.516)"
                        fill="#000000"
                      />
                      <path
                        id="Trazado_5061"
                        data-name="Trazado 5061"
                        d="M6,19.225,9.426,17.7l3.426,1.523L16.277,17.7,19.7,19.225V4H6ZM6.761,4.761h12.18V18.054l-2.355-1.047-.309-.137-.309.137-3.117,1.385L9.735,17.007l-.309-.137-.309.137L6.761,18.054Z"
                        transform="translate(-6 -4)"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  Ver documento
                </a>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-flex px-2 mb-3">
              <div class="check-box-condicion">
                <p class="mb-0">
                  Autorización Consultas a Centrales de Riesgo y Autorización
                  Tratamiento de datos personales
                </p>
                <a
                  href="http://api.devstirpe.com.co/docs/autorizacion_centrales_riesgo.pdf"
                  target="_blank"
                  download
                >
                  <svg
                    id="Shopicons_Light_Invoice"
                    style="margin-right: .3rem"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.271"
                    height="18.271"
                    viewBox="0 0 18.271 18.271"
                  >
                    <path
                      id="Trazado_5060"
                      data-name="Trazado 5060"
                      d="M0,0H18.271V18.271H0Z"
                      fill="none"
                    />
                    <g id="Shopicon" transform="translate(2.284 1.523)">
                      <rect
                        id="Rectángulo_2511"
                        data-name="Rectángulo 2511"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 3.426)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2512"
                        data-name="Rectángulo 2512"
                        width="6.851"
                        height="0.761"
                        transform="translate(3.426 6.471)"
                        fill="#000000"
                      />
                      <rect
                        id="Rectángulo_2513"
                        data-name="Rectángulo 2513"
                        width="3.806"
                        height="0.761"
                        transform="translate(3.426 9.516)"
                        fill="#000000"
                      />
                      <path
                        id="Trazado_5061"
                        data-name="Trazado 5061"
                        d="M6,19.225,9.426,17.7l3.426,1.523L16.277,17.7,19.7,19.225V4H6ZM6.761,4.761h12.18V18.054l-2.355-1.047-.309-.137-.309.137-3.117,1.385L9.735,17.007l-.309-.137-.309.137L6.761,18.054Z"
                        transform="translate(-6 -4)"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  Ver documento
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 p-2">
            <div class="box-tyc-cupo align-items-center justify-content-center">
              <label class="d-flex mx-0 ms-md-1">
                <input
                  id="tyc4"
                  v-model="pagare"
                  class="tyc-checkbox"
                  type="checkbox"
                  checkmark="checkmark"
                />
                <span class="checkmark"></span>
              </label>
              <div>
                <label
                  for="tyc4"
                  class="mb-0 p-0"
                  style="width: 100%;cursor: pointer; font-size: 1rem"
                  >Acepto todos los terminos y condiciones</label
                >
              </div>
            </div>
          </div>
          <div class="text-center mt-3 w-100 mx-5">
            <span v-if="msgError" class="msgError">{{ messagerError }}</span>
          </div>
          <div class="col-12 col-md-6 mx-auto mt-4">
            <button
              :disabled="!pagare"
              :class="pagare ? '' : 'disabled-btn'"
              class="btn-primary w-100"
              @click="validateDNI()"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
      <div v-if="stepCupo == 'TOKEN__SINGLE'">
        <p>
          <b
            >¡Hola
            {{
              dataCupo.cliente_nombre
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}!</b
          >
          <br />
          Para finalizar el proceso de pago es necesario validar tu identidad,
          Ingresa el código de seguridad que enviamos a tu número de celular
          {{ dataCupo.cliente_celular }}
        </p>
        <div class="check-code">
          <div
            class="col-12 col-md-auto payment-input mb-4 position-relative p-0"
            style="border-bottom: none"
          >
            <input
              v-model="codTokenCupo"
              class="payment-input my-0 py-0 w-100 payment-email-input"
              type="number"
              placeholder="Código"
            />
            <span v-if="codTokenCupo" class="newlatter-error-input"
              >Código</span
            >
          </div>
          <button
            class="payment-button-input btn-primary"
            @click="validateDNI()"
          >
            Finalizar pedido
          </button>
        </div>
        <div v-if="errorCupo" class="my-3 text-center">
          <span class="msgError text-center">{{ msgerrorCupo }}</span>
        </div>
        <div>
          <p>
            ¿No has recibido el código? <br />
            Selecciona un canal para el reenvío del código.
          </p>
          <div
            class="btn-resend"
            :style="
              reSendCodMSG
                ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
                : ''
            "
            @click="reSendCodMSG ? '' : resendCodCupo(1)"
          >
            <img src="@/assets/icons/sms-icon.svg" alt="" />
            <p>Reenviar SMS</p>
          </div>
          <div
            class="btn-resend"
            :style="
              reSendCodWPP
                ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
                : ''
            "
            @click="reSendCodWPP ? '' : resendCodCupo(2)"
          >
            <img src="@/assets/icons/whatsapp-icon-black.svg" />
            <p>Recibir Whatsapp</p>
          </div>
          <div
            class="btn-resend"
            :style="
              reSendCodCall
                ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
                : ''
            "
            @click="reSendCodCall ? '' : resendCodCupo(3)"
          >
            <img src="@/assets/icons/phone-icon.svg" alt="" />
            <p>Recibir Llamada</p>
          </div>
        </div>
        <div v-if="reSendCodMSG && reSendCodWPP && reSendCodCall">
          <p>
            ¿Aún no puedes recibes el codigo? <br />
            Ponte en contacto con nosotros
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=573232747474&text=Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto"
              >WhatsApp: 323 274 7474</a
            >
            / <a href="tel:018000423550">Línea Nacional: 01 8000 423550</a>
          </p>
        </div>
      </div>
      <!-- PASO FINALIZAR COMPRA -->
      <div v-if="stepCupo == 'FINALIZAR_PEDIDO'">
        <div class="box-message mb-4 p-3">
          <div
            class="mx-3 mx-md-5 p-1"
            style="border-radius: 5px; background: #d3d3d3ad"
          >
            <strong
              >Tu cupo actual disponible para compras es: ${{
                cupoDisponibleFP
              }}</strong
            >
          </div>
          <p class="mb-0">
            <strong>Pago con tu Cupo personal: ${{ totalCartFP }}</strong
            ><br />
            Tu cupo alcanza para pagar la totalidad de la compra
          </p>
        </div>
        <div>
          <div class="text-center mt-3 mx-5">
            <span v-if="msgError" class="msgError">{{ messagerError }}</span>
          </div>
          <div class="d-flex justify-content-center">
            <button
              class="btn-primary btn-finalize-payment"
              type=""
              @click="validateDNI()"
            >
              Finalizar compra
            </button>
          </div>
        </div>
      </div>
      <div v-if="stepCupo == 'CUPO_INSUFICIENTE'">
        <div class="box-message p-3">
          <div class="mx-3 mx-md-5" style="background: #d3d3d3ad">
            <p class="mb-0 p-2">
              <strong
                >Tu cupo actual disponible para compras es: ${{
                  avaibleSpace
                }}</strong
              >
            </p>
          </div>
          <p class="mb-0">
            <strong>Pago con tu Cupo personal: ${{ totalCart }}</strong
            ><br />
            Tu cupo NO alcanza para pagar la totalidad de la compra, puedes
            <router-link :to="{ name: 'MyBag' }" class="fs-6"
              ><strong style="color: black;"
                >modificar tu carrito</strong
              ></router-link
            >
            o selecciona un nuevo método de pago
          </p>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-token-cel"
      ref="modal-"
      v-model="modalTokenCel"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div v-if="!cancelFirme">
        <svg
          class="btn-close-modal-gray"
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          @click="cancelFirme = true"
        >
          <g
            id="Grupo_6059"
            data-name="Grupo 6059"
            transform="translate(-5105.212 99.512)"
          >
            <path
              id="Trazado_6"
              data-name="Trazado 6"
              d="M1.509.214,13.746,0A1.422,1.422,0,0,1,15.2,1.457a1.547,1.547,0,0,1-1.509,1.509L1.457,3.181A1.422,1.422,0,0,1,0,1.724,1.547,1.547,0,0,1,1.509.214Z"
              transform="translate(5107.461 -99.512) rotate(45)"
              fill="#c2c2c2"
            />
            <path
              id="Trazado_7"
              data-name="Trazado 7"
              d="M1.457,0,13.694.214A1.547,1.547,0,0,1,15.2,1.724a1.422,1.422,0,0,1-1.457,1.457L1.509,2.967A1.547,1.547,0,0,1,0,1.457,1.422,1.422,0,0,1,1.457,0Z"
              transform="translate(5105.212 -88.761) rotate(-45)"
              fill="#c2c2c2"
            />
          </g>
        </svg>
        <div v-if="sendSMSToken">
          <p class="text-center mb-5">
            Ahora ingresa el código que enviaremos a tu correo electrónico
            <b>{{ emailFP }}</b>
          </p>
        </div>
        <div v-else>
          <p class="text-center">
            Hola {{ nameCliente }}, es tu primera compra con nuestro cupo, para
            continuar debes realizar la firma del pagaré electrónica.
          </p>
          <p class="text-center mb-5">
            Ingresa el código que te enviaremos vía <b>SMS</b> a tu celular
            <b>No. {{ telCP }}</b>
          </p>
        </div>
        <div class="container-actions-token">
          <div class="d-flex justify-content-center">
            <img
              v-if="sendSMSToken"
              src="@/assets/icons/send-email-token.svg"
              style="margin-top: -32px; margin-bottom: 1rem"
            />
            <img
              v-else
              src="@/assets/icons/send-sms-token.svg"
              style="margin-top: -32px; margin-bottom: 1rem"
            />
          </div>
          <p class="text-center">
            <b
              >Ingresa el código enviado a tu
              {{ sendSMSToken ? 'correo' : 'celular' }}</b
            >
          </p>
          <div class="content-inputs">
            <input
              id="input-1"
              v-model="inputVal1"
              type="number"
              :class="msgError ? 'invalid-cod' : ''"
              pattern="[0-9]"
              maxlength="1"
              @keyup="inputTokenAndNext(inputVal1, 'input-2')"
            />
            <input
              id="input-2"
              v-model="inputVal2"
              type="number"
              :class="msgError ? 'invalid-cod' : ''"
              pattern="[0-9]"
              maxlength="1"
              @keyup="inputTokenAndNext(inputVal2, 'input-3')"
            />
            <input
              id="input-3"
              v-model="inputVal3"
              type="number"
              :class="msgError ? 'invalid-cod' : ''"
              pattern="[0-9]"
              maxlength="1"
              @keyup="inputTokenAndNext(inputVal3, 'input-4')"
            />
            <input
              id="input-4"
              v-model="inputVal4"
              type="number"
              :class="msgError ? 'invalid-cod' : ''"
              pattern="[0-9]"
              maxlength="1"
              @keyup="inputTokenAndNext(inputVal3, 'btn-send-cod-soli')"
            />
          </div>
          <div v-if="msgError" class="invalid-cod-msg">
            <p class="mb-0">{{ messagerError }}</p>
          </div>
          <button
            id="btn-send-cod-soli"
            variant="primary"
            class="btn-primary w-100"
            @click="
              sendSMSToken ? validateTokenEmailFirma() : validateTokenCelFirma()
            "
          >
            Continuar
            <svg
              style="margin-left: .4rem"
              xmlns="http://www.w3.org/2000/svg"
              width="15.54"
              height="16.782"
              viewBox="0 0 15.54 16.782"
            >
              <g
                id="Icon_feather-arrow-left"
                data-name="Icon feather-arrow-left"
                transform="translate(14.04 14.661) rotate(180)"
              >
                <path
                  id="Trazado_102"
                  data-name="Trazado 102"
                  d="M12.54,0H0"
                  transform="translate(0 6.27)"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
                <path
                  id="Trazado_103"
                  data-name="Trazado 103"
                  d="M6.27,12.54,0,6.27,6.27,0"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </g>
            </svg>
          </button>
        </div>
        <p class="text-center">¿No has recibido el código?</p>
        <button
          v-if="!sendSMSToken"
          id="btn-resend-code-sms"
          :disabled="countTimeResendCodPagareSMS > 0"
          :style="countTimeResendCodPagareSMS > 0 ? 'opacity: .6' : ''"
          class="w-100 secondary-button mb-3"
          @click="reSendSMSPagare()"
        >
          Reenviar código
        </button>
        <button
          v-else
          id="btn-resend-code-email"
          :disabled="countTimeResendCodPagareEMAIL > 0"
          :style="countTimeResendCodPagareEMAIL > 0 ? 'opacity: .6' : ''"
          class="w-100 secondary-button mb-3"
          @click="reSendEmailPagare()"
        >
          Reenviar código
        </button>
        <p class="text-center">
          Si tienes algún inconveniente, comunícate al <b>01 8000 180566</b> o a
          nuestro <b>WhatsApp: 323 274 7474</b>
        </p>
      </div>
      <div v-else>
        <p class="text-center mb-4">
          <b
            >¿Seguro que quieres abandonar el proceso de firma de pagaré? Está
            acción cancelará tu solicitud y no podrás finalizar la compra.</b
          >
        </p>
        <button
          :key="cancelFirme"
          class="btn-primary w-100 mb-4"
          @click="cancelFirme = false"
        >
          Continuar proceso de firma
        </button>
        <button
          :key="cancelFirme"
          class="w-100 mb-4 mx-auto secondary-button"
          @click="modalTokenCel = false"
        >
          Abandonar y cancelar compra
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GA4AddPayment, GA4Purchase } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  props: {
    reSendCreditCupo: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      modalTokenCel: false,
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      inputVal4: '',
      sendSMSToken: false,
      cancelFirme: false,
      telCP: '',
      codSMSFP: '',
      tempURLFP: '',
      tempFormIdFP: '',
      nameCliente: '',
      emailFP: '',
      countTimeResendCodPagareSMS: 0,
      countTimeResendCodPagareEMAIL: 0,
      /** */
      validateData: false,
      msgError: false,
      loading: false,
      messagerError: '',
      url: '/payment/cupopersonal/validarDocumentoClienteCupo',
      stepCupo: '',
      typePago: 'mensual',
      ccUser: '',
      codTokenCupo: '',
      reSendCodWPP: false,
      reSendCodMSG: false,
      reSendCodCall: false,
      soliEmail: '',
      soliCC: '',
      soliName: '',
      soliLastname: '',
      soliCiudad: '',
      soliPhone: '',
      nit: '',
      fechExp: null,
      pagare: false,
      emailCod: '',
      celCod: '',
      tokenProcess: '',
      codCel: '',
      codEmail: '',
      cupoDisponibleFP: '',
      totalCartFP: '',
      avaibleSpace: '',
      totalCart: '',
      docFP: '',
      cupoCodigoSMSFP: '',
      tokenProcessFP: '',
      nameFirmaPagarePre: '',
      countTimeResendCodPagare: 0,
      citys: [],
      selectCity: null,
      errorCity: false,
      selectDep: null,
    }
  },
  computed: {
    ...mapState('cart', [
      'dataCart',
      'dataCupo',
      'errorCupo',
      'msgerrorCupo',
      'productsCart',
    ]),
    ...mapState('products', ['deps', 'shippingsTotal']),
    ...mapState(['esFE']),
    /* eslint-disable */
    validateEmailSoli() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.soliEmail)
    },
    validateSoliCC() {
      return this.soliCC.toString().length > 4
    },
    validateSoliName() {
      return this.soliName.length > 2
    },
    validateSoliLastName() {
      return this.soliLastname.toString().length > 2
    },
    validateFechExp() {
      if (this.fechExp != null) {
        let dateSplit = this.fechExp.split('-')
        let dateNow = new Date()
        if (dateNow.getFullYear() > parseInt(dateSplit[0])) {
          return true
        } else if (dateNow.getFullYear() == parseInt(dateSplit[0])) {
          if (dateNow.getMonth() + 1 > parseInt(dateSplit[1])) {
            return true
          } else if (dateNow.getMonth() + 1 == dateSplit[1]) {
            if (dateNow.getDate() > parseInt(dateSplit[2])) {
              return true
            } else if (dateNow.getDate() == dateSplit[2]) {
              return true
            }
          }
        }
      }
      return false
    },
    validateSoliCiudad() {
      return this.selectCity
    },
    validateSoliPhone() {
      return this.soliPhone.toString().length == 10
    },
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    getDataProductsCRTO() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  watch: {
    selectDep() {
      this.selectCity = null
      this.$store.state.products.shippingsTotal = null
      this.citys = this.selectDep.ciudades
    },
    reSendCreditCupo() {
      if (this.reSendCreditCupo > 0) {
        this.validateDNI()
      }
    },
    modalTokenCel() {
      if (!this.modalTokenCel) {
        this.url = this.tempURLFP
        this.stepCupo = this.tempFormIdFP
        this.inputVal1 = ''
        this.inputVal2 = ''
        this.inputVal3 = ''
        this.inputVal4 = ''
        this.sendSMSToken = false
        this.cancelFirme = false
      }
    },
  },
  methods: {
    ...mapActions('cart', [
      'validateDocCupo',
      'resendCodCupoCall',
      'resendCodCupoWPP',
      'resendCodCupoSMS',
      'validateTokenCupo',
    ]),
    ...mapActions('products', ['getDataCitys']),
    inputTokenAndNext(prev, next) {
      this.inputVal1 = this.inputVal1 ? this.inputVal1[this.inputVal1.length - 1] : ''
      this.inputVal2 = this.inputVal2 ? this.inputVal2[this.inputVal2.length - 1] : ''
      this.inputVal3 = this.inputVal3 ? this.inputVal3[this.inputVal3.length - 1] : ''
      this.inputVal4 = this.inputVal4 ? this.inputVal4[this.inputVal4.length - 1] : ''
      if (prev.length > 0) {
        document.getElementById(next).focus()
      }
    },
    async validateTokenCelFirma() {
      this.msgError = false
      this.messagerError = ''
      let code =
        this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4
      if (code.length == 4) {
        this.validateDNI()
      } else {
        this.msgError = true
        this.messagerError = 'Código requerido'
      }
    },
    async validateTokenEmailFirma() {
      this.msgError = false
      this.messagerError = ''
      let code =
        this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4
      if (code.length == 4) {
        this.validateDNI()
      } else {
        this.msgError = true
        this.messagerError = 'Código requerido'
      }
    },
    async reSendSMSPagare() {
      let data = new FormData()
      data.append('token', window.localStorage.getItem('tokenCart'))
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/cupopersonal/reenviarCodigoSMSPagare',
        data: data,
      }).then(() => {
        this.updateCountTimeResendCodPagareSMS(60)
      })
    },
    async reSendEmailPagare() {
      let data = new FormData()
      data.append('token', window.localStorage.getItem('tokenCart'))
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/cupopersonal/reenviarCodigoEMAILPagare',
        data: data,
      }).then(() => {
        this.updateCountTimeResendCodPagareEMAIL(60)
      })
    },
    validatedocument() {
      if (this.ccUser) {
        this.validateDocCupo({ doc: this.ccUser }).then(() => {
          if (!this.errorCupo) {
            this.stepCupo = this.dataCupo.accion
          }
        })
      }
    },
    async sendCodCupo() {
      await this.validateTokenCupo({
        doc: this.ccUser,
        cupoCodigoSMS: this.codTokenCupo,
      }).then(() => {
        if (!this.errorCupo) {
          this.$emit('nextStep', 1)
        }
      })
    },
    async resendCodCupo(step) {
      this.$store.state.loaderDerek = true
      if (step == 1) {
        await this.resendCodCupoSMS({ doc: this.ccUser }).then(() => {
          if (!this.errorCupo) {
            this.reSendCodMSG = true
            this.$store.state.loaderDerek = false
          }
        })
      } else if (step == 2) {
        await this.resendCodCupoWPP({ doc: this.ccUser }).then(() => {
          if (!this.errorCupo) {
            this.reSendCodWPP = true
            this.$store.state.loaderDerek = false
          }
        })
      } else if (step == 3) {
        await this.resendCodCupoCall({ doc: this.ccUser }).then(() => {
          if (!this.errorCupo) {
            this.reSendCodCall = true
            this.$store.state.loaderDerek = false
          }
        })
      }
    },
    async validateCupoQuac() {
      this.$store.state.loaderDerek = true
      this.$store.state.cartmsgModalExcepcion406 = ''
      this.$store.state.cartbtnModalExcepcion406 = ''
      this.validateData = false
      this.loading = true
      let data = new FormData()
      const token = window.localStorage.getItem('tokenCart')
      data.append('token', token)
      sendFBQEvent(this, 'InitiateCheckout', {})
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/quac/compra',
        data: data,
      })
        .then(response => {
          GA4AddPayment('Quac')
          GA4Purchase()
          location.href = response.data
        })
        .catch(err => {
          this.validateData = false
          this.loading = false
          this.$store.state.loaderDerek = false
          this.$store.state.cart.errorCupo = true
          if (err.response.status == 423) {
            this.$store.state.showModal423 = true
          } else if (err.response.status == 500) {
            this.msgError = true
            this.messagerError =
              'Parece que algo salió mal, por favor intenta más tarde.'
            this.loading = false
            this.tempUrl = ''
          } else if (err.response.status == 406) {
            this.$bvModal.show('modal-error-406')
            this.$store.state.cartmsgModalExcepcion406 =
              err.response.data.errors.bono_regalo[0]
            this.$store.state.cartbtnModalExcepcion406 = 'cupo'
          } else {
            var messages = ``
            if (err.response.data.message) {
              messages = err.response.data.message
            } else {
              var obj = err.response.data.errors
              for (const prop in obj) {
                messages += `${obj[prop][0]}\n`
              }
            }
            this.msgError = true
            this.messagerError = messages
            this.$store.state.cart.msgerrorCupo = messages
            this.loading = false
            this.$store.state.loaderDerek = false
            this.tempUrl = ''
          }
        })
    },
    async validateDNI() {
      this.$store.state.loaderDerek = true
      this.$store.state.cartmsgModalExcepcion406 = ''
      this.$store.state.cartbtnModalExcepcion406 = ''
      if (!this.loading) {
        this.validateData = false
        this.loading = true
        let data = new FormData()
        this.$store.state.cart.errorCupo = false
        this.$store.state.cart.msgerrorCupo = ''
        this.msgError = false
        this.messagerError = ''
        data.append('token', window.localStorage.getItem('tokenCart'))
        data.append('pedido_fe_dian', this.esFE)
        if (this.stepCupo == '') {
          data.append('documento', this.ccUser)
        } else if (this.stepCupo == 'REQUIERE_SOLICITUD') {
          if (
            this.validateEmailSoli &&
            this.validateSoliCC &&
            this.validateSoliName &&
            this.validateSoliLastName &&
            this.validateSoliCiudad &&
            this.validateSoliPhone &&
            this.validateFechExp
          ) {
            this.validateData = false
            sendFBQEvent(this, 'Lead', {})
            data.append('correo1', this.soliEmail)
            data.append('documento', this.soliCC)
            data.append('nombres', this.soliName)
            data.append('apellidos', this.soliLastname)
            data.append('ciudad', this.selectCity.codigo)
            data.append('departamento', this.selectDep.codigo)
            data.append('celular1', this.soliPhone)
            data.append('fecha_expedicion', this.fechExp)
          } else {
            this.validateData = true
            this.$store.state.loaderDerek = false
            return
          }
          // } else if (this.stepCupo == 'ENVIAR_CODIGOS_PAGARE'){
          //     data.append('documento', this.documentCP)
        } else if (this.stepCupo == 'ENVIAR_CODIGO_SMS_PAGARE') {
          data.append('documento', this.documentCP)
        } else if (this.stepCupo == 'FIRMA__PAGARE_SMS') {
          data.append(
            'cupoCodigoSMS',
            this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4,
          )
          // } else if (this.stepCupo == 'FIRMA__PAGARE_PREAPROBADO') {
          //     if(this.codCel && this.codEmail){
          //         data.append('documento', this.ccUser);
          //         data.append('cupoCodigoSMS', this.codCel);
          //         data.append('cupoCodigoCorreo', this.codEmail);
          //         data.append('tokenProcess', this.tokenProcess);
          //     }else{
          //         this.validateData = true;
          //         this.$store.state.loaderDerek = false;
          //         return;
          //     }
        } else if (this.stepCupo == 'FIRMA__PAGARE_PREAPROBADO') {
          data.append('cupoCodigoSMS', this.codSMSFP)
          data.append(
            'cupoCodigoCorreo',
            this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4,
          )
        } else if (this.stepCupo == 'TOKEN__SINGLE') {
          data.append('documento', this.ccUser)
          data.append('cupoCodigoSMS', this.codTokenCupo)
          data.append('tokenProcess', this.tokenProcessTCP)
        } else if (this.stepCupo == 'ACTIVACION_CUPO') {
          data.append('documento', this.clientNITAC)
          data.append('nombres', this.clientNameAC)
          data.append('apellidos', this.clientLastnameAC)
          data.append('celular', this.clientCelAC)
          data.append('correo', this.clientEmailAC)
          data.append('clave', this.clientPassAC)
          data.append('clave2', this.clientConfPassAC)
          data.append('cupoCodigoSMS', this.clientCodSMSAC)
          data.append('tokenProcess', this.tokenProcessAC)
        } else if (this.stepCupo == 'FINALIZAR_PEDIDO') {
          sendFBQEvent(this, 'InitiateCheckout', {})
          data.append('documento', this.docFP)
          data.append('cupoCodigoSMS', this.cupoCodigoSMSFP)
          data.append('tokenProcess', this.tokenProcessFP)
        }
        // try {
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: this.url,
          data: data,
        })
          .then(response => {
            this.loading = false
            this.$store.state.loaderDerek = false
            if (response.data.response.form_id != 'RESUMEN_PEDIDO') {
              this.url = response.data.response.data_form.url
              this.stepCupo = response.data.response.data_form.form_id
              this.formHTLM = response.data.form
              this.step = 2
              if (this.stepCupo == 'REQUIERE_SOLICITUD') {
                // this.soliEmail = response.data.response.data_form.carrito_cliente_correo
                this.soliCC = parseFloat(
                  response.data.response.data_form.cliente_documento,
                )
                // this.soliPhone = response.data.response.data_form.carrito_cliente_celular
                // this.soliName = response.data.response.data_form.carrito_cliente_nombre
                // this.soliLastname = response.data.response.data_form.carrito_cliente_apellidos
                // this.soliCiudad = response.data.response.data_form.ciudadDescripcion
                // } else if (this.stepCupo == 'ENVIAR_CODIGOS_PAGARE'){
                //     this.documentCP = response.data.response.data_form.cliente_documento
                //     this.cupoDisponible = response.data.response.data_form.cupo_disponible
                //     this.totalCarrito = response.data.response.data_form.totalCarrito
              } else if (this.stepCupo == 'ENVIAR_CODIGO_SMS_PAGARE') {
                this.documentCP =
                  response.data.response.data_form.cliente_documento
                this.cupoDisponible =
                  response.data.response.data_form.cupo_disponible
                this.totalCarrito =
                  response.data.response.data_form.totalCarrito
                this.tempURLFP = response.data.response.data_form.url
                this.tempFormIdFP = response.data.response.data_form.form_id
                this.nameCliente =
                  response.data.response.data_form.cliente_nombre
              } else if (this.stepCupo == 'FIRMA__PAGARE_SMS') {
                this.modalTokenCel = true
                this.telCP = response.data.response.data_form.cliente_celular
                this.updateCountTimeResendCodPagareSMS(60)
              } else if (this.stepCupo == 'FIRMA__PAGARE_PREAPROBADO') {
                this.sendSMSToken = true
                this.emailFP = response.data.response.data_form.cliente_correo
                this.codSMSFP =
                  this.inputVal1 +
                  this.inputVal2 +
                  this.inputVal3 +
                  this.inputVal4
                this.inputVal1 = ''
                this.inputVal2 = ''
                this.inputVal3 = ''
                this.inputVal4 = ''
                this.updateCountTimeResendCodPagareEMAIL(60)
                if(response.data.response.data_form.error_crm_correo != '') {
                  this.msgError = true
                  this.messagerError = response.data.response.data_form.error_crm_correo
                }
                // }else if (this.stepCupo == 'FIRMA__PAGARE_PREAPROBADO') {
                //     this.emailCod = response.data.response.data_form.cliente_correo
                //     this.celCod = response.data.response.data_form.cliente_celular
                //     this.nameFirmaPagarePre = response.data.response.data_form.cliente_nombre
                //     this.tokenProcess = response.data.response.data_form.tokenProcess
                //     this.updateCountTimeResendCodPagare(60);
              } else if (this.stepCupo == 'TOKEN__SINGLE') {
                this.$store.state.cart.dataCupo =
                  response.data.response.data_form
                this.url = response.data.response.data_form.url
                this.tokenProcessTCP =
                  response.data.response.data_form.tokenProcess
              } else if (this.stepCupo == 'CUPO_INSUFICIENTE') {
                this.avaibleSpace =
                  response.data.response.data_form.cupo_disponible
                this.totalCart = response.data.response.data_form.total_carrito
              } else if (this.stepCupo == 'ACTIVACION_CUPO') {
                this.clientNameAC =
                  response.data.response.data_form.cliente_nombre
                this.clientLastnameAC =
                  response.data.response.data_form.cliente_apellidos
                this.clientNITAC =
                  response.data.response.data_form.cliente_documento
                this.clientCelAC =
                  response.data.response.data_form.cliente_celular
                this.clientEmailAC =
                  response.data.response.data_form.cliente_correo
                this.cupoDisponibleAC =
                  response.data.response.data_form.cupo_disponible
                this.totalCarritoAC =
                  response.data.response.data_form.totalCarrito
                this.clientCodSMSAC =
                  response.data.response.data_form.cupoCodigoSMS
                this.tokenProcessAC =
                  response.data.response.data_form.tokenProcess
              } else if (this.stepCupo == 'FINALIZAR_PEDIDO') {
                this.docFP = response.data.response.data_form.cliente_documento
                this.cupoCodigoSMSFP =
                  response.data.response.data_form.cupoCodigoSMS
                this.cupoDisponibleFP =
                  response.data.response.data_form.cupo_disponible
                this.tokenProcessFP =
                  response.data.response.data_form.tokenProcess
                this.totalCartFP =
                  response.data.response.data_form.total_carrito
              }
            } else {
              this.$store.state.cart.dataSummaryPayment = response.data.response
              sendFBQEvent(this, 'Purchase', {
                value: response.data.response.pedido.carrito.carrito_total,
                currency: 'COP',
                contents: response.data.response.productos_purchase,
                content_ids: response.data.response.productos_purchase.map(
                  e => e.id,
                ),
                event_id: this.dataSummaryPayment.pedido.pedido
                  .pedido_reference_sale,
                content_type: 'product',
              })
              // this.$gtag.event('checkoutOption', {
              //   event: 'checkoutOption',
              //   ecommerce: {
              //     checkout_option: {
              //       actionField: {
              //         step: 3,
              //         option:
              //           response.data.response.pedido.pedido.transaccion
              //             .transaccion_metodopago,
              //       },
              //     },
              //   },
              // })
              // this.$gtag.event('transaction', {
              //   event: 'transaction',
              //   ecommerce: {
              //     purchase: {
              //       actionField: {
              //         id: response.data.response.pedido.carrito.reference_sale,
              //         affiliation: 'Compra Online Derek.co',
              //         revenue:
              //           response.data.response.pedido.carrito.carrito_total,
              //         tax: 0,
              //         shipping:
              //           response.data.response.pedido.carrito.carrito_envio,
              //         coupon:
              //           response.data.response.pedido.carrito.cupon_codigo,
              //       },
              //       products: this.getDataAnalytics,
              //     },
              //   },
              // })
              // // *Evento Criteo*
              // this.$gtag.event('crto_transactionpage', {
              //   event: 'crto_transactionpage',
              //   crto: {
              //     email: response.data.response.pedido.carrito.cliente_correo,
              //     transactionid:
              //       response.data.response.pedido.carrito.reference_sale,
              //     products: this.getDataProductsCRTO,
              //   },
              // })
              var deviceType = /iPad/.test(navigator.userAgent)
                ? 't'
                : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                    navigator.userAgent,
                  )
                ? 'm'
                : 'd'
              window.criteo_q.push(
                { event: 'setAccount', account: 67539 },
                {
                  event: 'setEmail',
                  email: response.data.response.pedido.carrito.cliente_correo,
                },
                { event: 'setSiteType', type: deviceType },
                { event: 'setZipcode', zipcode: '##Zip Code##' },
                {
                  event: 'trackTransaction',
                  id: response.data.response.pedido.carrito.reference_sale,
                  item: this.getDataProductsCRTO,
                },
              )
              this.$emit('nextStep')
              window.localStorage.removeItem('tokenCart')
              this.$emit('nextStep', 1)
            }
          })
          .catch(err => {
            console.log(err)
            this.$store.state.loaderDerek = false
            this.$store.state.cart.errorCupo = true
            if (err.response.status == 423) {
              this.$store.state.showModal423 = true
            } else if (err.response.status == 500) {
              this.msgError = true
              this.messagerError =
                'Parece que algo salió mal, por favor intenta más tarde.'
              this.loading = false
              this.tempUrl = ''
            } else if (err.response.status == 406) {
              this.$bvModal.show('modal-error-406')
              this.$store.state.cartmsgModalExcepcion406 =
                err.response.data.errors.bono_regalo[0]
              this.$store.state.cartbtnModalExcepcion406 = 'cupo'
            } else {
              var messages = ``
              if (err.response.data.message) {
                message = err.response.data.message
              } else {
                var obj = err.response.data.errors
                for (const prop in obj) {
                  messages += `${obj[prop][0]}\n`
                }
              }
              this.msgError = true
              this.messagerError = messages
              this.$store.state.cart.msgerrorCupo = messages
              this.loading = false
              this.$store.state.loaderDerek = false
              this.tempUrl = ''
            }
          })
        // } catch (err) {
        //     this.textNotification = 'Parece que algo salió mal, por favor intenta más tarde.';
        //     console.log('No se que paso!')
        // }
      }
      this.loading = false
      this.$store.state.loaderDerek = false
    },

    // async resendCodigosPagare(){
    //     let data = new FormData();
    //     data.append('token', window.localStorage.getItem('tokenCart'));
    //     data.append('documento', this.documentCP);
    //     await this.axios({
    //         method: 'POST',
    //         headers: { 'content-type': 'application/x-www-form-urlencoded'},
    //         url: '/payment/cupopersonal/reenviarCodigosPagare',
    //         data: data
    //     }).then((response) => {
    //         this.updateCountTimeResendCodPagare(60);
    //     });
    // },
    async updateCountTimeResendCodPagareSMS(time) {
      let btnChange = document.getElementById('btn-resend-code-sms')
      this.countTimeResendCodPagareSMS = time
      if (
        time > 0 &&
        this.countTimeResendCodPagareSMS > 0 &&
        this.modalTokenCel
      ) {
        if (btnChange) {
          btnChange.innerHTML = 'Reenviar código en ' + time.toString()
        }
        await setTimeout(() => {
          if (this.countTimeResendCodPagareSMS > 0) {
            this.updateCountTimeResendCodPagareSMS(time - 1)
          }
        }, 1000)
      } else {
        btnChange.innerHTML = 'Reenviar código'
        this.countTimeResendCodPagareSMS = 0
      }
    },
    async updateCountTimeResendCodPagareEMAIL(time) {
      let btnChange = document.getElementById('btn-resend-code-email')
      this.countTimeResendCodPagareEMAIL = time
      if (
        time > 0 &&
        this.countTimeResendCodPagareEMAIL > 0 &&
        this.modalTokenCel
      ) {
        if (btnChange) {
          btnChange.innerHTML = 'Reenviar código en ' + time.toString()
        }
        await setTimeout(() => {
          if (this.countTimeResendCodPagareEMAIL > 0) {
            this.updateCountTimeResendCodPagareEMAIL(time - 1)
          }
        }, 1000)
      } else {
        btnChange.innerHTML = 'Reenviar código'
        this.countTimeResendCodPagareEMAIL = 0
      }
    },
  },
  mounted() {
    this.ccUser = this.dataCart.cliente_documento
    if (!(this.deps.length > 0)) {
      this.getDataCitys()
    }
  },
}
</script>
