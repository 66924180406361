<template>
  <div class="col-12 px-3">
    <data-summary-payment
      :data-response="dataSummaryPayment.pedido"
      :products="dataSummaryPayment.pedido.productos"
    />
  </div>
  <!-- <div class="col-12 py-4 payment-finish-content">
    <h3>¡Gracias por tu compra!</h3>
    <div v-if="!sendedPoll" class="content-rating-order d-none d-md-block">
      <p>
        <strong
          >¿Qué probabilidades hay de que recomiendes Derek a un familiar o
          amigo?</strong
        >
      </p>
      <div class="rating-order">
        <div class="d-none d-md-block">
          <span class="w-100 pe-2">😢</span>
          <button
            v-for="(item, index) in 11"
            :key="index + item"
            :class="range == index ? 'selected' : ''"
            class="btn-rating-order-summary"
            @click="range = index"
          >
            {{ index }}
          </button>
          <span class="w-100">🤩</span>
          <div class="p-n-probable col-12 col-md-10">
            <p>Nada probable</p>
            <p>Muy probable</p>
          </div>
        </div>
        <div class="d-flex flex-wrap d-md-none my-5">
          <div class="w-100 d-flex justify-content-between mb-4">
            <span>😢</span>
            <span>🤩</span>
          </div>
          <div class="w-100">
            <input
              id="range-slider"
              v-model="range"
              type="range"
              list="tickmarks"
              min="0"
              max="10"
            />
            <div class="position-relative d-flex">
              <div
                :style="
                  range > 0 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 0 ? 'd-none' : ''"
                style="position: absolute; left: 5%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 1 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 1 ? 'd-none' : ''"
                style="position: absolute; left: 15%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 2 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 2 ? 'd-none' : ''"
                style="position: absolute; left: 23%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 3 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 3 ? 'd-none' : ''"
                style="position: absolute; left: 32%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 4 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 4 ? 'd-none' : ''"
                style="position: absolute; left: 41%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 5 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 5 ? 'd-none' : ''"
                style="position: absolute; left: 50%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 6 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 6 ? 'd-none' : ''"
                style="position: absolute; left: 59%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 7 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 7 ? 'd-none' : ''"
                style="position: absolute; left: 68%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 8 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 8 ? 'd-none' : ''"
                style="position: absolute; left: 76.5%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 9 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 9 ? 'd-none' : ''"
                style="position: absolute; left: 85%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 10 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 10 ? 'd-none' : ''"
                style="position: absolute; left: 94%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
            </div>
            <label class="value-range" :style="valueRatingMovil">{{
              range
            }}</label>
          </div>
        </div>
        <div>
          <textarea
            v-model="mensaje"
            rows="5"
            class="text-area-order-summary"
            placeholder="¿Qué mejorarías de nuestro sitio?"
          ></textarea>
        </div>
        <div
          class="d-flex mb-3 justify-content-center col-12 col-md-8 col-xl-7 px-0 px-md-2 px-xl-3 mx-auto"
        >
          <button
            class="w-100 btn-primary btn-finalize-payment"
            style="max-width: 450px"
            @click="registerPoll()"
          >
            Enviar comentario
          </button>
        </div>
        <div>
          <span v-if="errorEncuesta" class="msgError"
            >El puntaje es requerido</span
          >
          <span v-if="errorSurvey" class="msgError"
            >No se pudo registrar la encuesta</span
          >
        </div>
      </div>
    </div>
    <div class="message-order-summary">
      <img src="@/assets/icons/check-icon-gray.svg" alt="" />
      <p>
        <strong
          >Pedido No. {{ dataSummaryPayment.pedido.pedido.pedido_id }}</strong
        >
        <br />
        El pedido está confirmado, muy pronto recibirás un correo electrónico
        con todos los detalles de tu compra.
      </p>
      <div
        v-if="
          dataSummaryPayment.pedido.pedido.transaccion &&
            dataSummaryPayment.pedido.pedido.transaccion
              .transaccion_urlefectybaloto
        "
        class="mb-3"
      >
        <a
          target="_blank"
          class="btn-primary"
          :href="
            dataSummaryPayment.pedido.pedido.transaccion
              .transaccion_urlefectybaloto
          "
          >Ver cupón de pago</a
        >
      </div>
    </div>
    <div
      class="info-tiempo-entrega message-order-summary p-0"
      style="background-color: transparent;"
    >
      <div
        style="border-radius: 5px; overflow: hidden;"
        v-html="dataSummaryPayment.pedido.html"
      />
    </div>
    <div class="address-order-summary">
      <p>
        <strong>Dirección de envío</strong><br />
        {{ dataSummaryPayment.pedido.carrito.cliente_nombre }}
        {{ dataSummaryPayment.pedido.carrito.cliente_apellidos }}<br />
        {{ dataSummaryPayment.pedido.carrito.cliente_direccion }} -
        {{ dataSummaryPayment.pedido.carrito.cliente_barrio }}<br />
        {{ dataSummaryPayment.pedido.carrito.observaciones }}<br />
        {{
          dataSummaryPayment.pedido.carrito.cliente_ciudad_texto
            .split(',')[0]
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase())
        }},
        {{
          dataSummaryPayment.pedido.carrito.cliente_ciudad_texto
            .split(',')[1]
            .trim()
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase())
        }}<br />
        {{ dataSummaryPayment.pedido.carrito.cliente_celular }}<br />
      </p>
    </div>
    <div v-if="!sendedPoll" class="content-rating-order d-block d-md-none">
      <p>
        <strong
          >¿Qué probabilidades hay de que recomiende Derek a un amiga?</strong
        >
      </p>
      <div class="rating-order">
        <div class="d-none d-md-block">
          <span class="w-100 pe-2">😢</span>
          <button
            v-for="(item, index) in 11"
            :key="index + item"
            :class="range == index ? 'selected' : ''"
            class="btn-rating-order-summary"
            @click="range = index"
          >
            {{ index }}
          </button>
          <span class="w-100">🤩</span>
          <div class="p-n-probable col-12 col-md-10">
            <p>Nada probable</p>
            <p>Muy probable</p>
          </div>
        </div>
        <div class="d-flex flex-wrap d-md-none my-5">
          <div class="w-100 d-flex justify-content-between mb-4">
            <span>😢</span>
            <span>🤩</span>
          </div>
          <div class="w-100">
            <input
              id="range-slider"
              v-model="range"
              type="range"
              list="tickmarks"
              min="0"
              max="10"
            />
            <div class="position-relative d-flex">
              <div
                :style="
                  range > 0 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 0 ? 'd-none' : ''"
                style="position: absolute; left: 5%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 1 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 1 ? 'd-none' : ''"
                style="position: absolute; left: 15%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 2 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 2 ? 'd-none' : ''"
                style="position: absolute; left: 23%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 3 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 3 ? 'd-none' : ''"
                style="position: absolute; left: 32%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 4 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 4 ? 'd-none' : ''"
                style="position: absolute; left: 41%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 5 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 5 ? 'd-none' : ''"
                style="position: absolute; left: 50%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 6 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 6 ? 'd-none' : ''"
                style="position: absolute; left: 59%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 7 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 7 ? 'd-none' : ''"
                style="position: absolute; left: 68%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 8 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 8 ? 'd-none' : ''"
                style="position: absolute; left: 76.5%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 9 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 9 ? 'd-none' : ''"
                style="position: absolute; left: 85%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
              <div
                :style="
                  range > 10 ? 'background-color: #8DCFD7; width: 3px;' : ''
                "
                :class="range == 10 ? 'd-none' : ''"
                style="position: absolute; left: 94%; top: 7px; z-index: 0; width: 2px; height: 10px; background-color: grey;"
              />
            </div>
            <label class="value-range" :style="valueRatingMovil">{{
              range
            }}</label>
          </div>
        </div>
        <div>
          <textarea
            v-model="mensaje"
            rows="5"
            class="text-area-order-summary"
            placeholder="¿Qué mejorarías de nuestro sitio?"
          ></textarea>
        </div>
        <div
          class="d-flex mb-3 justify-content-center col-12 col-md-8 col-xl-7 px-0 px-md-2 px-xl-3 mx-auto"
        >
          <button
            class="w-100 btn-primary btn-finalize-payment"
            style="max-width: 450px"
            @click="registerPoll()"
          >
            Enviar comentario
          </button>
        </div>
        <div>
          <span v-if="errorEncuesta" class="msgError"
            >El puntaje es requerido</span
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="my-2 text-center">
        <h3 class="mb-2">Gracias por tu respuesta</h3>
        <p class="fs-6 mb-4">Tendremos en cuenta tus comentarios</p>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-5 mx-auto text-center mb-5 px-3 px-xl-1">
      <button
        class="w-100 fs-6 btn-primary"
        style="max-width: 450px"
        @click="
          $store.state.cart.productsCart = null
          $store.state.cart.dataCart = null
          $router.replace({ name: 'Home' })
        "
      >
        Volver a la tienda
      </button>
    </div>
    <div class="help-text">
      <p>
        ¿Necesitas ayuda? Comunícate con nuestra línea de atención 323 274 7474
      </p>
    </div>
  </div> -->
</template>
<script>
import DataSummaryPayment from '@/components/Payment/DataSummaryPayment.vue'
import { mapState, mapActions } from 'vuex'
export default {
  components: { DataSummaryPayment },
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      range: null,
      mensaje: '',
      sendedPoll: false,
      styleRange: [
        'left: calc(-50% + 17px);',
        'left: calc(-40% + 15px);',
        'left: calc(-30% + 10px);',
        'left: calc(-20% + 6px);',
        'left: calc(-10% + 3px);',
        'left: 0;',
        'left: calc(10% - 3px);',
        'left: calc(20% - 6px);',
        'left: calc(30% - 10px);',
        'left: calc(40% - 15px);',
        'left: calc(50% - 17px);',
      ],
      errorEncuesta: false,
    }
  },
  computed: {
    ...mapState('cart', ['dataSummaryPayment', 'errorSurvey', 'productsCart']),
    valueRatingMovil() {
      return this.styleRange[this.range]
    },
  },
  created() {
    window.scrollTo(0, 0)
    // this.$gtag.event('purchase', {
    //   affiliation: 'Derek',
    //   coupon: this.dataSummaryPayment.pedido.carrito.cupon_codigo,
    //   currency: 'COP',
    //   items: this.dataSummaryPayment.productos,
    //   transaction_id: this.dataSummaryPayment.pedido.carrito.carrito_id,
    //   shipping: this.dataSummaryPayment.pedido.carrito.carrito_envio,
    //   value: this.dataSummaryPayment.pedido.carrito.carrito_subtotal,
    // })
    window.sendinblue.identify(
      this.dataSummaryPayment.pedido.carrito.cliente_correo,
    )
    window.sendinblue.track(
      'purchase_derek',
      {
        email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
      },
      {
        id: this.dataSummaryPayment.pedido.pedido_id,
        data: {
          revenue: this.dataSummaryPayment.carrito.carrito_total,
          venta_cruzada: this.dataSummaryPayment.dataTrackPurchaseendinblue
            .venta_cruzada,
          venta_genero: this.dataSummaryPayment.dataTrackPurchaseendinblue
            .genero_id,
          items: this.dataSummaryPayment.dataTrackPurchaseendinblue
            .items_carrito,
          items_complementarios: this.dataSummaryPayment
            .dataTrackPurchaseendinblue.items_complementarios,
        },
      },
    )
  },
  beforeDestroy() {
    this.$store.state.cart.dataCart = null
    this.$store.state.cart.productsCart = null
  },
  methods: {
    ...mapActions('cart', ['RegisterPollCheckout']),
    async registerPoll() {
      this.errorEncuesta = false
      if (this.range) {
        await this.RegisterPollCheckout({
          pedido_id: this.dataSummaryPayment.pedido.pedido
            .pedido_reference_sale,
          puntaje: this.range,
          mensaje: this.mensaje,
        }).then(() => {
          if (!this.errorSurvey) {
            this.sendedPoll = true
          }
        })
      } else {
        this.errorEncuesta = true
      }
    },
  },
}
</script>
