<template>
  <div class="content-payment-addi">
    <p class="text-center">
      <b>
        Finaliza tu compra con ADDI Es simple, rápido y seguro:
      </b>
    </p>
    <p class="text-center mb-4">
      Paga a cuotas con ADDI
      <br />
      <span style="font-size: 12px;">*sujeto a aprobación</span>
    </p>
    <div class="d-flex flex-wrap justify-content-center mb-4">
      <div class="col-12 col-sm-6 col-lg d-flex px-sm-2">
        <p class="box-info-addi">
          Sin tarjeta de crédito y en minutos
        </p>
      </div>
      <div class="col-12 col-sm-6 col-lg d-flex px-sm-2">
        <p class="box-info-addi">
          Paga tu primera cuota un mes después de la compra.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-lg d-flex px-sm-2">
        <p class="box-info-addi">
          Proceso 100% online. Sin papeleo ni costos ocultos
        </p>
      </div>
    </div>
    <p class="text-center mb-4">¡Solo necesitas tu cédula y WhatsApp!</p>
    <div class="d-flex justify-content-center mb-4 col-12 col-lg-8 mx-auto">
      <button
        class="btn-primary btn-finalize-payment w-100"
        @click="goToPayAddi()"
      >
        Pagar con Addi
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GA4AddPayment, GA4Purchase } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('cart', ['errorAddi', 'urlPayAddi']),
  },
  methods: {
    ...mapActions('cart', ['payAddi']),
    async goToPayAddi() {
      this.$store.state.loaderDerek = true
      sendFBQEvent(this, 'InitiateCheckout', {})
      await this.payAddi()
        .then(() => {
          if (this.showModal423) {
            this.$store.state.loaderDerek = false
          } else if (this.msgModalExcepcion406) {
            this.$store.state.loaderDerek = false
            this.$bvModal.show('modal-error-406')
          } else {
            GA4AddPayment('Addi')
            GA4Purchase()
            location.href = this.urlPayAddi
          }
        })
        .catch(() => {
          this.$store.state.loaderDerek = false
        })
    },
  },
}
</script>
